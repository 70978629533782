function createObject() {
  let example = {
    id: "LBST_HR_Stempeln_Diagnose2",
    workOrder: "Betrachten Sie die Lösung von Pauline zu folgender Aufgabe:",
    image: require("@/assets/hr/Stempeln/Dia2.png"),
    elements: [
      [
        {
          question:
            "Welche halbschriftliche Strategie versucht Pauline hier vermutlich zu nutzen?",
          answers: [
            {
              correct: false,
              text: "Hilfsaufgabe",
              response:
                "Wir denken nicht, dass Pauline versucht hat die Strategie Hilfsaufgabe zu nutzen. Bei einer Hilfsaufgabe wird eine ähnliche, einfachere Aufgabe berechnet und in einem zweiten Schritt wieder auf die Ausgangsaufgabe zurückgeführt.",
            },
            {
              correct: false,
              text: "Schrittweise",
              response:
                "Wir denken nicht, dass Pauline versucht hat die Strategie Schrittweise zu nutzen, da sie sowohl Multiplikator als auch Multiplikand zerlegt. Dieses Vorgehen passt nicht zur Strategie Schrittweise. Bei der Ausgangsaufgabe bietet sich ein schrittweises Vorgehen zudem nicht vordergründig als geschickteste Strategie an, da mit großen und unhandlichen Teilergebnissen gerechnet werden muss.",
            },
            {
              correct: true,
              text: "Stellenweise",
              response:
                "Genau, wir denken auch, dass Pauline versucht hat, die Strategie Stellenweise zu nutzen, da sie sowohl Multiplikator als auch Multiplikand in ihre Stellenwerte zerlegt.",
            },
          ],
        },
      ],
      [
        {
          question: "Was macht Pauline hier schon richtig?",
          answers: [
            {
              correct: false,
              text:
                "Vollständige Zerlegung der Ausgangsaufgabe in Teilaufgaben",
              response:
                "Pauline zerlegt zwar 63 richtig in 60 und 3 und 26 in 20 und 6. Allerdings multipliziert sie jeweils lediglich die jeweils gleichen Stellenwerte (Z·Z und E·E) miteinander.",
            },
            {
              correct: true,
              text: "Ermittlung korrekter Zwischenergebnisse",
              response:
                "Genau, Pauline hat hier Zwischenergebnisse korrekt berechnet.",
            },
            {
              correct: false,
              text: "Ermittlung des korrekten Produkts",
              response:
                "Zwar hat Pauline die beiden ermittelten Teilprodukte richtig berechnet und addiert, jedoch ist die Zerlegung der Ausgangsaufgabe in Teilaufgaben im Sinne der verwendeten Strategie zur halbschriftlichen Multiplikation unvollständig.",
            },
          ],
        },
      ],
      [
        {
          question:
            "Welchen Fehler macht Pauline bei der Bearbeitung der Aufgabe?",
          answers: [
            {
              correct: false,
              text: "Falsche Notationsweise der Zwischenergebnisse",
              response:
                "Paulines gefundenen Teilrechnung notiert sie korrekt stellengerecht. Allerdings liegt das Fehlerhafte Endprodukt an ihren Teilrechnungen. Überprüfen Sie diese nocheinmal.",
            },
            {
              correct: false,
              text: "Fehlerhafte Zerlegung der Faktoren",
              response:
                "Pauline hat die Faktoren hier korrekt zerlegt. Sie hat die Strategie Stellenweise genutzt und demnach beide Faktoren nach Stellenwerten zerlegt und korrekt miteinander verknüpft.",
            },
            {
              correct: true,
              text: "Unvollständige Bestimmung der Teilprodukte",
              response:
                "Genau, Pauline hat hier nicht alle Teilprodukte bestimmt. Sie hat beide Faktoren zwar stellenweise zerlegt, jedoch nicht vollständig miteinander verrechnet. Ihre Rechnung beinhaltet jeweils nur die Multiplikaton der Zehnerstelle miteinander und der Einerstelle miteinander. Dadurch fehlen in ihrer Rechnung die Teilprodukte zur Multiplikation der Zehnerstelle des Multiplikators mit der Einerstelle des Multiplikanden und die der Einerstelle des Multiplikators mit der Zehnerstelle des Multiplikanden. Somit gelangt sie nicht zum korrekten Gesamtprodukt.",
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
