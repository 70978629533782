function createObject() {
  let example = {
    id: "LBST_HR_Stempeln_Diagnose1",
    workOrder: "Betrachten Sie die Lösung von Elias zu folgender Aufgabe:",
    image: require("@/assets/hr/Stempeln/Dia1.png"),
    elements: [
      [
        {
          question:
            "Welche halbschriftliche Strategie versucht Elias hier vermutlich zu nutzen? ",
          answers: [
            {
              correct: false,
              text: "Hilfsaufgabe",
              response:
                "Wir denken nicht, dass Elias versucht hat die Strategie Hilfsaufgabe zu nutzen. Elias nutzt keinen zweiten Schritt, um seine Rechnung auszugleichen.",
            },
            {
              correct: false,
              text: "Schrittweise",
              response:
                "Wir denken, dass die Strategie Schrittweise hier nicht genutzt wurde. Schauen Sie sich nochmal die Bearbeitung von Elias an. Elias teilt sich keinen der beiden Summanden schrittweise auf.",
            },
            {
              correct: true,
              text: "Vereinfachen",
              response:
                "Genau, wir denken auch, dass Elias versucht hat, die Strategie Vereinfachen zu nutzen.",
            },
          ],
        },
      ],
      [
        {
          question: "Was macht Elias hier schon richtig?",
          answers: [
            {
              correct: true,
              text: "Richtiges Berechnen der selbst aufgestellten Aufgabe",
              response:
                "Ja, Elias hat die Aufgabe, die er ersatzweise gerechnet hat, korrekt gelöst.",
            },
            {
              correct: false,
              text: "Ermittlung des korrekten Ergebnisses der Ausgangsaufgabe",
              response:
                "Nein, Elias hat anscheinend die von ihm genutzte halbschriftliche Strategie zur Addition noch nicht richtig umgesetzt. Seine Berechnung hat hier nicht zum richtigen Ergebnis der Ausgangsaufgabe geführt.",
            },
            {
              correct: false,
              text: "Richtiger Ausgleich durch gleichsinniges Verändern",
              response:
                "Falsch, Elias hat seine Ausgangsaufgabe gleichsinnig verändert, jedoch ist dies bei der Addition nicht zielführend, um auf das richtige Ergebnis der Ausgangsaufgabe zu kommen.",
            },
          ],
        },
      ],
      [
        {
          question:
            "Welchen Fehler macht Elias bei der Bearbeitung der Aufgabe?",
          answers: [
            {
              correct: false,
              text:
                "Keine sinnvolle Auswahl einer passenden halbschriftlichen Strategie",
              response:
                "Vermutlich möchte Elias hier die Strategie Vereinfachen nutzen. Er nimmt vermutlich wahr, dass der erste Summand nah an der 450 und erhöht den ersten Summanden um eins. Den zweiten Summanden erhöht er ebenfalls um eins. Dies führt allerdings nicht zur richtigen Summe.",
            },
            {
              correct: false,
              text: "Nutzung der Umkehroperation",
              response:
                "Wir sehen nicht, dass Elias hier die Umkehroperation, in diesem Fall die Subtraktion, genutzt hat.",
            },
            {
              correct: true,
              text: "Falsche Nutzung des Konstanzgesetzes",
              response:
                "Genau, Elias verändert die Summanden gleichsinnig statt gegensinnig, da er beide Summanden um eins erhöht. Dadurch ist die Summe um zwei zu groß. Da es sich hier um eine Additionsaufgabe handelt, hätte Elias die Summanden gegensinnig verändern müssen, um die Erhöhung des ersten Summanden um eins durch die Verringerung des zweiten Summanden um eins wieder auszugleichen.",
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
