<template>
  <div>
    <h2 class="faledia-caption">Diagnose & Förderung</h2>
    <p>
      Auch wenn das Hintergrundwissen essentiell ist, ist es nicht weniger
      wichtig zu trainieren, dieses Wissen auf konkrete Dokumente von Kindern
      anwenden zu können. Vor allem ist es dabei zentral erfassen zu können, in
      welchen Bereichen das Zahlenrechnen bereits gut ausgebildet ist und wo
      eventuell Probleme vorliegen. Noch nicht tragfähige Vorstellungen und
      Sicherheiten zum Zahlenrechnen können sich in verschiedenen Fehlern
      äußern. Da die Strategien vielzählig sind, die beim halbschriftlichen
      Rechnen in den verschiedenen Operationen angewendet werden, ist folglich
      auch die Anzahl typischer Fehler, die auf Fehlkonzepte und Schwierigkeiten
      beim Zahlenrechnen hindeuten, mannigfaltig. Daher wird sich im Folgenden
      lediglich einer Auswahl bedient. Wichtig ist aber immer ein produktiver
      Umgang mit Fehlern.
    </p>
    <p>
      Das Wissen um solche typischen Fehler ist für Lehrkräfte von großer
      Bedeutung, damit diese erkannt werden können und die Ursache mit den
      Kindern aufgearbeitet werden kann. Oftmals lassen sich aus sich
      wiederholenden Fehlern Fehlvorstellungen und Verständnisprobleme ableiten,
      denen es entsprechend zu begegnen gilt. Eine verständnisbasierte
      Erarbeitung der halbschriftlichen Strategien ist zum einen zentral, um
      Fehlvorstellungen vorzubeugen, andererseits aber auch immer wieder Ziel,
      wenn Fehler auf Verständnisprobleme hinweisen. Zunächst ist es immer
      sinnvoll sich zur Diagnose der Vorgehensweisen von Lernenden intensiv mit
      deren Lösungen auseinanderzusetzen. Betrachten Sie dazu im Folgenden das
      Vorgehen des Schülers Elias sowie der Schülerin Pauline. Die abgebildeten
      Bearbeitungen geben jeweils Hinweise darauf, was gegebenenfalls noch nicht
      Verstanden wurde. Machen Sie sich in einem ersten Schritt Gedanken dazu,
      welche Strategie das jeweilige Kind versucht zu nutzen und überlegen Sie
      im Anschluss, was bereits gut klappt und wo das Vorgehen noch zu Fehlern
      führt.
    </p>
    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[0].id"
        :workOrder="LBSTStempeln[0].workOrder"
        :image="LBSTStempeln[0].image"
        :audio="LBSTStempeln[0].audio"
        :elements="LBSTStempeln[0].elements"
        :showAlternative="false"
      ></LernbausteinStempeln>
    </div>
    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[1].id"
        :workOrder="LBSTStempeln[1].workOrder"
        :image="LBSTStempeln[1].image"
        :audio="LBSTStempeln[1].audio"
        :elements="LBSTStempeln[1].elements"
        :showAlternative="false"
      ></LernbausteinStempeln>
    </div>
    <p>
      Nachdem Sie sich nun einmal mit Kinderlösungen auseinandergesetzt haben,
      können Sie sich nachfolgend über typische Fehler informieren.
      Typischerweise können Fehler in die Kategorien Verständnisfehler,
      Rechenfehler und Merkfehler eingeteilt werden. Hinzu kommen typische
      Fehler in den einzelnen Rechenoperationen (Götze et al., 2019). Abgesehen
      davon, dass es typische Fehler gibt, gilt es dabei aber immer zu beachten,
      dass ein einmaliges Auftreten eines Fehlers nicht immer zwangsläufig mit
      einem Fehlkonzept oder grundlegend fehlendem Verständnis einhergehen muss.
      Bei Verdachtsmomenten sollte stets weiter diagnostiziert und nachgefragt
      werden, wie das Kind vorgegangen ist.
    </p>
    <p>
      Verständnisfehler sind oft das Ergebnis von fehlerhaften Vorstellungen
      oder von unreflektiertem Übertragen von Rechengesetzen. Um dem
      entgegenzuwirken ist es wichtig, bei den Kindern einen Verständnisaufbau
      zu fördern und Einsichten in die jeweiligen Fehler zu ermöglichen. Dies
      kann je nach Vorgehensweise beispielsweise durch die Veranschaulichung
      mithilfe von Dienes-Material oder dem Rechenstrich geschehen. Dadurch
      können sowohl Sie als Lehrkraft als auch die Kinder ihre Fehler besser
      nachvollziehen und in einer daran anknüpfenden Förderung tragfähige
      Vorstellung entwickeln (vgl. Götze, Selter, Zannetin, 2019, S.99).
      Verständnisfehler können sich beispielsweise in den folgenden
      Vorgehensweisen äußern. Bitte beachten Sie, dass sich es hierbei lediglich
      um eine Auswahl handelt.
    </p>
    <v-simple-table class="imageTable">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Art des Fehlers</td>
            <td>Beispiel</td>
          </tr>
          <tr>
            <td>Hilfsaufgaben falsch ausgleichen (Richtungsfehler)</td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  contain
                  :src="require('@/assets/hr/DuFTabelle1.png')"
                  parentId="swve"
                  elementId="zr_tab1.png"
                >
                </AppExpandableImage>
              </div>
            </td>
          </tr>
          <tr>
            <td>Falsche Nutzung der Konstanzgesetze</td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  contain
                  :src="require('@/assets/hr/DuFTabelle2.png')"
                  parentId="swve"
                  elementId="zr_tab2.png"
                >
                </AppExpandableImage>
              </div>
            </td>
          </tr>
          <tr>
            <td>Konsequente Subtraktion der kleineren von der größeren Zahl</td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  contain
                  :src="require('@/assets/hr/DuFTabelle3.png')"
                  parentId="swve"
                  elementId="zr_tab3.png"
                >
                </AppExpandableImage>
              </div>
            </td>
          </tr>
          <tr>
            <td>Fehlerhafte Verknüpfung von Zwischenergebnissen</td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  contain
                  :src="require('@/assets/hr/DuFTabelle4.png')"
                  parentId="swve"
                  elementId="zr_tab4.png"
                >
                </AppExpandableImage>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p>
      Im nachfolgenden Beispiel haben Sie nun abschließend die Gelegenheit
      zunächst eine Feindiagnostik auf Grundlage erster diagnostischer
      Erkenntnisse durchzuführen und im Anschluss daran eine geeignete
      Förderaufgabe auszuwählen.
    </p>
    <div style="background-color: white">
      <LernbausteinPfade
        :id="LBSTPfade[0].id"
        :workOrder="LBSTPfade[0].workOrder"
        :image="LBSTPfade[0].image"
        :imageSizePercent="LBSTPfade[0].imageSizePercent"
        :mediaDescription="LBSTPfade[0].mediaDescription"
        :audio="LBSTPfade[0].audio"
        :elements="LBSTPfade[0].elements"
        :responses="LBSTPfade[0].responses"
        :pupilName="LBSTPfade[0].pupilName"
      />
    </div>

    <AppLiteraturHR />
    <AppBottomNavHR
      next="/zahlenrechnen/check"
      back="/zahlenrechnen/uebergang"
    />
  </div>
</template>

<script>
import AppBottomNavHR from "@/common/AppBottomNavHR";
import AppLiteraturHR from "@/common/AppLiteraturHR";
import AppExpandableImage from "@/common/AppExpandableImage";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinPfade from "@/components/faledia/explorativeElemente/LernbausteinPfade";
import LBST_Stempeln_Dia1 from "@/components/faledia/seiten/zahlenrechnen/LBST/LBST_Stempeln_Diagnose1";
import LBST_Stempeln_Dia2 from "@/components/faledia/seiten/zahlenrechnen/LBST/LBST_Stempeln_Diagnose2";
import LBST_Pfade_Lea from "@/components/faledia/seiten/zahlenrechnen/LBST/LBST_Pfade_Lea";

export default {
  components: {
    AppBottomNavHR,
    AppLiteraturHR,
    LernbausteinStempeln,
    AppExpandableImage,
    LernbausteinPfade,
  },
  data: () => ({
    LBSTStempeln: [LBST_Stempeln_Dia1, LBST_Stempeln_Dia2],
    LBSTPfade: [LBST_Pfade_Lea],
  }),
};
</script>
<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
.imageTableImages {
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageTable {
  margin-bottom: 20px;
}

.tableImageContainer {
  width: 179px;
}
</style>
