function createObject() {
  let example = {
    id: "LBST_Pfade_Lea",
    workOrder:
      "Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.",
    image: require("@/assets/hr/Pfade/Lea/01.png"),
    imageSizePercent: 50,
    mediaDescription:
      "Lea hat die Aufgabe 123 – 89 wie folgt bearbeitet und erklärt ihr Vorgehen.",
    pupilName: "Lea",
    elements: [
      [
        {
          selectCount: 2,
          typeText: "Diagnoseaufgabe",
          question:
            "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/hr/Pfade/Lea/02.png"),
              solutionImage: require("@/assets/hr/Pfade/Lea/03.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/hr/Pfade/Lea/04.png"),
              solutionImage: require("@/assets/hr/Pfade/Lea/05.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/hr/Pfade/Lea/06.png"),
              solutionImage: require("@/assets/hr/Pfade/Lea/07.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/hr/Pfade/Lea/08.png"),
              solutionImage: require("@/assets/hr/Pfade/Lea/09.png"),
            },
          ],
        },
      ],
      [
        {
          selectCount: 1,
          typeText: "Förderaufgabe",
          question:
            "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/hr/Pfade/Lea/10.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/hr/Pfade/Lea/11.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/hr/Pfade/Lea/12.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/hr/Pfade/Lea/13.png"),
            },
          ],
        },
      ],
    ],
  };

  const DA_1 =
    "Diagnoseaufgabe 1 lässt erkennen, dass Lea eine Subtraktionsaufgabe ohne Stellenwertübergang korrekt stellenweise lösen kann. ";

  const DA_2 =
    "Diagnoseaufgabe 2 zeigt, dass Lea beim stellenweisen Subtrahieren anscheinend konsequent die kleinere Zahl von der größeren Zahl abzieht, wenn der Minuend kleiner ist als der Subtrahend. ";

  const DA_3 =
    "Diagnoseaufgabe 3 zeigt, dass Lea die Aufgabe schrittweise am Rechenstrich korrekt lösen und darstellen kann. ";

  const DA_4 =
    "Diagnoseaufgabe 4 zeigt, dass Lea richtig den Minuenden als Zahlbild darstellen kann. Sie scheint allerdings Schwierigkeiten damit zu haben, dass der Minuend an der Einerstelle kleiner ist als der Subtrahend und kann die Aufgabe nicht am Zahlbild lösen. ";

  const FA_1 =
    "Förderaufgabe 1 regt durch den Vergleich von Leas Vorgehensweisen einen kognitiven Konflikt an. Da Lea anscheinend schrittweise am Rechenstrich Aufgaben mit Stellenwertübergang korrekt lösen kann, jedoch noch Probleme damit hat, diese stellenweise zu lösen, wird Lea vermutlich auf unterschiedliche Differenzen kommen. Diese Förderaufgabe kann dazu beitragen, dass Lea ihr Vorgehen reflektiert. ";

  const FA_1_STAR =
    "Förderaufgabe 1 regt die halbschriftliche Strategie ‚schrittweise‘ an. Die von Ihnen gewählten Diagnoseaufgaben zeigen jedoch, wie Lea beim stellenweisen Subtrahieren vorgeht. Sie müssten zunächst diagnostizieren, inwiefern Lea schrittweise Subtrahieren kann, um zu entscheiden, ob Förderaufgabe 2 für Lea hilfreich wäre. ";

  const FA_2 =
    "Förderaufgabe 2 regt durch den Vergleich von Leas Vorgehensweisen mit der stellenweisen Vorgehensweise eines anderen Kindes einen kognitiven Konflikt an. Die Lösung und die Notation des anderen Kindes sind korrekt. Zunächst soll Lea beschreiben, was das andere Kind gemacht hat, wodurch sie den Lösungsweg nachvollzieht. Der Vergleich mit ihrer eigenen Lösung kann anschließend dazu beitragen, dass Lea ihr Vorgehen reflektiert. ";

  const FA_3 =
    "Die Lösung, die bei Förderaufgabe 3 aufgrund der vorherigen Diagnose zu erwarten wäre, wäre, dass Lea die Aufgabe richtig stellenweise löst. Diagnoseaufgabe 1 zeigt, dass Lea keine Probleme mit dem stellenweisen Subtrahieren hat, solange der Minuend an jeder Stelle kleiner als der Subtrahend ist. Zur Förderung in Bezug auf Leas Schwierigkeiten beim stellenweisen Subtrahieren mit Stellenwertübergängen, ist diese Aufgabe somit nicht geeignet. ";

  const FA_3_STAR =
    "Förderaufgabe 3 beinhaltet eine Aufgabe, bei der jede Stelle des Minuenden größer als die des Subtrahenden ist. Lea scheint allerdings Probleme beim stellenweisen Subtrahieren mit Stellenwertübergang zu haben, was die Förderaufgabe jedoch nicht anregt. Zur Diagnose, ob Lea grundsätzlich Probleme beim stellenweisen Subtrahieren hat, wäre diese Aufgabe interessant. Zur Förderung in Bezug auf Leas Schwierigkeiten beim stellenweisen Subtrahieren mit Stellenwertübergängen, ist diese Aufgabe jedoch nicht geeignet. ";

  const FA_4 =
    "In Förderaufgabe 4 soll Lea eine Aufgabe ohne Stellenwertübergang am Rechenstrich lösen. Dies regt ein schrittweises Vorgehen an. Die Diagnose zeigt allerdings, dass Lea gerade Probleme damit hat, Subtraktionsaufgaben mit Stellenwertübergang stellenweise zu lösen. Zur Förderung in Bezug auf Leas Schwierigkeiten, ist diese Aufgabe somit nicht geeignet. ";

  const FA_4_STAR =
    "In Förderaufgabe 4 soll Lea eine Aufgabe ohne Stellenwertübergang am Rechenstrich lösen. Dies regt ein schrittweises Vorgehen an. Diagnoseaufgabe 3 zeigt, dass Lea selbst mit Stellenwertübergang keine Probleme damit hat, Subtraktionsaufgaben schrittweise am Rechenstrich zu lösen. Zur Förderung in Bezug auf Leas Schwierigkeiten beim stellenweisen Subtrahieren mit Stellenwertübergängen, ist diese Aufgabe somit nicht geeignet. ";

  const PASSUNG_SEHR_GUT = `
  Diese Förderaufgabe passt sehr gut zur Diagnose.<br />`;
  const PASSUNG_GUT = `
  Diese Förderaufgabe passt ganz gut zur Diagnose.<br />`;
  const PASSUNG_SCHLECHT = `
  Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.<br />`;
  const PASSUNG_SEHR_SCHLECHT = `
  Diese Aufgabe hätten wir nicht gewählt.<br />`;

  example.responses = [
    {
      id: "112",
      text: PASSUNG_SCHLECHT + DA_1 + DA_2 + FA_1_STAR,
    },
    {
      id: "113",
      text: PASSUNG_GUT + DA_1 + DA_3 + FA_1,
    },
    {
      id: "114",
      text: PASSUNG_SCHLECHT + DA_1 + DA_4 + FA_1_STAR,
    },
    {
      id: "123",
      text: PASSUNG_SEHR_GUT + DA_2 + DA_3 + FA_1,
    },
    {
      id: "124",
      text: PASSUNG_SCHLECHT + DA_2 + DA_4 + FA_1_STAR,
    },
    {
      id: "134",
      text: PASSUNG_SEHR_GUT + DA_3 + DA_4 + FA_1,
    },
    {
      id: "212",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_2 + FA_2,
    },
    {
      id: "213",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_3 + FA_2,
    },
    {
      id: "214",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_2,
    },
    {
      id: "223",
      text: PASSUNG_SEHR_GUT + DA_2 + DA_3 + FA_2,
    },
    {
      id: "224",
      text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_2,
    },
    {
      id: "234",
      text: PASSUNG_SEHR_GUT + DA_3 + DA_4 + FA_2,
    },
    {
      id: "312",
      text:
        PASSUNG_SEHR_SCHLECHT +
        DA_1 +
        DA_2 +
        FA_3,
    },
    {
      id: "313",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_3,
    },
    {
      id: "314",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_3,
    },
    {
      id: "323",
      text:
        PASSUNG_SCHLECHT +
        DA_2 +
        DA_3 +
        FA_3_STAR,
    },
    {
      id: "324",
      text: PASSUNG_SCHLECHT + DA_2 + DA_4 + FA_3_STAR,
    },
    {
      id: "334",
      text: PASSUNG_SCHLECHT + DA_3 + DA_4 + FA_3_STAR,
    },
    {
      id: "412",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_4,
    },
    {
      id: "413",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_4_STAR,
    },
    {
      id: "414",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_4,
    },
    {
      id: "423",
      text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_4_STAR,
    },
    {
      id: "424",
      text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_4,
    },
    {
      id: "434",
      text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_4_STAR,
    },
  ];

  return example;
}
export default createObject();
